import { mapToCanActivate, Routes } from '@angular/router';
import { ActiveUserGuard } from './helper/guards/active-user.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'onboarding',
    canActivate: mapToCanActivate([ActiveUserGuard]),
    loadChildren: () =>
      import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
];
