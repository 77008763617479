import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import {
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideNgVibeDialog } from '@ng-vibe/dialog';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideToastr } from 'ngx-toastr';
import { routes } from './app.routes';
import { tokenInterceptor } from './helper/interceptors/token.interceptor';

// AoT requires an exported function for factories
export function HttpLoaderFactory(_http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(_http);
}

export function appInitializerFactory(
  _cookie: CookieService,
  _translate: TranslateService
) {
  return () => {
    //TODO: Create API call to return user lang from DB
    const cookieLang = _cookie.get('lang');
    const lang = cookieLang && cookieLang !== '' ? cookieLang : 'en';
    _translate.setDefaultLang(lang);
    return _translate.use(lang);
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideHttpClient(withFetch(), withInterceptors([tokenInterceptor])),
    provideEnvironmentNgxMask(),
    importProvidersFrom([
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient, CookieService],
        },
      }),
    ]),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [CookieService, TranslateService],
      multi: true,
    },
    provideNgVibeDialog(),
    provideAnimations(),
    provideToastr(),
  ],
};
