import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../../services/auth.service';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const _authService = inject(AuthService);
  let authReq: any = req;

  const token = _authService._getToken();

  if (token) {
    const authToken = `Bearer ${token.replace('"', '')}`;
    authReq = req.clone({
      headers: req.headers.set('Authorization', authToken),
    });
  }

  return next(authReq).pipe(
    catchError((err: HttpErrorResponse) => {
      if (
        (err?.status === 0 && err?.name === 'HttpErrorResponse') ||
        err?.status === 401
      ) {
        _authService.logOut();
      }
      const error =
        err.error?.message || err.error || err.message || err.statusText;
      return throwError(() => error);
    })
  );
};
